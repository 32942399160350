<template>
  <div>
    <div
      class="form-builder"
      ref="formBuilder"
      id="formBuilder"
      :style="getStylediv"
      v-if="isExecute && !dataId"
    >
      <!-- <b v-if="!isFormFormbuilder">
        Fields :: {{ lastInteactiveQuestionIndex + 1 }} /
        {{ fieldsData.length }}
      </b> -->

      <pulse-loader
        v-if="!completedFieldsData.length && interactiveQuestionsLoading"
      ></pulse-loader>
      <div
        v-if="
          completedFieldsData && completedFieldsData.length && !refreshTemplate
        "
        class="form-fields-holder"
        :style="isFormFormbuilder ? '' : 'margin-left: 100px;'"
      >
        <el-scrollbar
          wrap-style="max-width: calc(100% - 0px);height: calc(100vh - 338px);"
          style="width: 100%; overflow-x: hidden"
        >
          <div
            v-for="(field, index) of completedFieldsData"
            :key="index"
            :style="
              interactiveQuestionsModeType == 'vertical' ||
              (currentQuestionIndexs.indexOf(index) > -1 &&
                completedFieldsData[lastInteactiveQuestionIndex])
                ? getStyle(field)
                : 'display: none;'
            "
          >
            <template
              v-if="
                interactiveQuestionsModeType == 'vertical' ||
                (currentQuestionIndexs.indexOf(index) > -1 &&
                  completedFieldsData[lastInteactiveQuestionIndex])
              "
            >
              <HorizontalLineExecute
                :data="field"
                v-if="field.input_type === 'HORIZONTAL_LINE'"
                :form="form"
                :is-view="!isExecute"
              ></HorizontalLineExecute>
              <DateExecute
                :data="field"
                v-if="field.input_type === 'DATE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></DateExecute>

              <DateTimeExecute
                :data="field"
                v-if="field.input_type === 'DATE_TIME'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></DateTimeExecute>
              <DateTimeRangeExecute
                :data="field"
                v-if="field.input_type === 'DATE_TIME_RANGE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></DateTimeRangeExecute>
              <DateRangeExecute
                :data="field"
                v-if="field.input_type === 'DATE_RANGE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></DateRangeExecute>
              <TimeExecute
                :data="field"
                v-if="field.input_type === 'TIME'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></TimeExecute>
              <TimeRangeExecute
                :data="field"
                v-if="field.input_type === 'TIME_RANGE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></TimeRangeExecute>
              <FileExecute
                :data="field"
                v-if="field.input_type === 'FILE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :isExecute="!is - view"
              ></FileExecute>
              <MultiLineTextExecute
                :data="field"
                v-if="
                  field.input_type === 'MULTI_LINE_TEXT' ||
                  field.input_type === 'RICH_TEXT'
                "
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></MultiLineTextExecute>
              <!-- {{field.input_type   }} -->
              <SingleLineTextExecute
                :data="field"
                v-if="
                  field.input_type === 'SINGLE_LINE_TEXT' ||
                  field.input_type === 'EMAIL' ||
                  field.input_type === 'PASSWORD' ||
                  field.input_type === 'MASKED' ||
                  field.input_type === 'HYPER_LINK'
                "
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></SingleLineTextExecute>
              <EmailExecute
                :data="field"
                v-if="field.input_type === 'EMAIL'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></EmailExecute>
              <!-- <PasswordExecute
                :data="field"
                v-if="field.input_type === 'PASSWORD'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></PasswordExecute>
              <MaskedExecute
                :data="field"
                v-if="field.input_type === 'MASKED'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></MaskedExecute> -->
              <MultiSelectExecute
                :data="field"
                v-if="field.input_type === 'MULTI_SELECT'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></MultiSelectExecute>
              <SelectExecute
                :data="field"
                v-if="field.input_type === 'SELECT'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></SelectExecute>
              <YesOrNoExecute
                :data="field"
                v-if="field.input_type === 'YES_OR_NO'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></YesOrNoExecute>
              <NumberExecute
                :data="field"
                v-if="field.input_type === 'NUMBER'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></NumberExecute>
              <PhoneCountryCodeExecute
                :data="field"
                v-if="field.input_type === 'PHONE_COUNTRY_CODE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></PhoneCountryCodeExecute>

              <CurrencyExecute
                :data="field"
                v-if="field.input_type === 'CURRENCY'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></CurrencyExecute>
              <PaymentExecute
                :data="field"
                v-if="field.input_type === 'PAY_BUTTON'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></PaymentExecute>

              <HeadingExecute
                :data="field"
                v-if="field.input_type === 'HEADING'"
                :form="form"
                :is-view="!isExecute"
              ></HeadingExecute>
              <CheckBoxExecute
                :data="field"
                v-if="field.input_type === 'CHECKBOX'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="getFieldsData"
                :singleCompanyTemplateCheckBoxArray="
                  singleCompanyTemplateCheckBoxArray
                "
              ></CheckBoxExecute>
              <RadioExecute
                :data="field"
                v-if="field.input_type === 'RADIO'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="getFieldsData"
              ></RadioExecute>
              <RadioGroupExecute
                :data="field"
                v-if="field.input_type === 'RADIO_BUTTON_GROUP'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="getFieldsData"
              ></RadioGroupExecute>

              <CheckBoxGroupExecute
                :data="field"
                v-if="field.inputType === 'CHECKBOX_GROUP'"
                :form="form"
                :isView="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="getFieldsData"
              ></CheckBoxGroupExecute>

              <ESignatureExecute
                :data="field"
                v-if="field.input_type === 'SIGNATURE'"
                :form="form"
                :hasLabel="hasLabel"
              ></ESignatureExecute>
              <GlobalVariableExecute
                :data="field"
                v-if="field.input_type === 'GLOBAL_VARIABLE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="fieldsData"
              ></GlobalVariableExecute>

              <EntityVariableExecute
                :data="field"
                v-if="field.input_type === 'ENTITY_VARIABLE'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="fieldsData"
                :class="'dataTable' + index"
              ></EntityVariableExecute>

              <EntityExecute
                :data="field"
                v-if="field.input_type === 'ENTITY'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :fieldsData="fieldsData"
              ></EntityExecute>

              <ParagraphExecute
                :data="field"
                v-if="field.input_type === 'PARAGRAPH'"
                :form="form"
                :is-view="!isExecute"
              ></ParagraphExecute>
              <SingleLineContentExecute
                :data="field"
                v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></SingleLineContentExecute>
              <AuthorizedSignatureExecute
                :data="field"
                v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
                :form="form"
                :is-view="!isExecute"
              ></AuthorizedSignatureExecute>
              <FormulaExecute
                :data="field"
                v-if="field.input_type === 'FORMULA'"
                :form="form"
                :is-view="!isExecute"
                :workflowData="getUserWorkflowData"
              ></FormulaExecute>

              <ActionButtonExecute
                :data="field"
                v-if="field.input_type === 'ACTION_BUTTON'"
                :form="form"
                :is-view="!isExecute"
                :workflowDataId="workflowDataId"
                :fieldsData="fieldsData"
              ></ActionButtonExecute>

              <ImageExecute
                :data="field"
                v-if="field.input_type === 'IMAGE'||
                field.input_type === 'GIF'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></ImageExecute>
              <VideoExecute
                :data="field"
                v-if="field.input_type === 'VIDEO'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></VideoExecute>
              <HtmlExecute
                :data="field"
                v-if="field.input_type === 'HTML_CONTENT'"
                :form="form"
                :is-view="!isExecute"
              ></HtmlExecute>

              <QuestionExecuteInteractive
                :data="field"
                v-if="field.input_type === 'QUESTION'"
                :form="form"
                :is-view="!isExecute"
                @question-answered="moveToNextQuestion"
                @goto-prev="moveToPrevQuestion"
                @goto-next="moveToNextQuestion"
                :nextIndex="index"
                :currentQuestionIndexs="currentQuestionIndexs"
                :lastInteactiveQuestionIndex="lastInteactiveQuestionIndex"
                :interactiveQuestionsModeType="interactiveQuestionsModeType"
                :fieldsData="fieldsData"
                :resetQuestionireForm="resetQuestionireForm"
              ></QuestionExecuteInteractive>

              <ListExecute
                :data="field"
                v-if="field.input_type === 'LIST'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
              ></ListExecute>
              <AggregateFunctionExecute
                :data="field"
                v-if="field.input_type === 'AGGREGATE_FUNCTION'"
                :form="form"
                :is-view="!isExecute"
                :hasLabel="hasLabel"
                :workflowDataId="workflowDataId"
              ></AggregateFunctionExecute>
              <WeekDaysExecute
                :data="field"
                v-if="field.input_type == 'WEEKDAYS'"
                :form="form"
                :hasLabel="hasLabel"
                :is-view="!isExecute"
              >
              </WeekDaysExecute>
              <StarRatingExecute
                :data="field"
                v-if="field.input_type == 'STAR_RATING'"
                :form="form"
                :hasLabel="hasLabel"
                :is-view="!isExecute"
              >
              </StarRatingExecute>
              <DataTableExecute
                :class="'dataTable' + index"
                ref="dataTable"
                :data="field"
                v-if="field.input_type === 'DATA_TABLE'"
                :form="form"
                :hasLabel="hasLabel"
                :fieldsData="fieldsData"
                @updateTableData="updateTableFieldData"
                @onNewRowAdded="updateTopForBelowElements(index)"
              ></DataTableExecute>
              <ConcatenateExecute
                :data="field"
                v-if=" field.input_type === 'CONCATENATE' "
                :form="form"
                :hasLabel="hasLabel"
                :is-view="!isExecute"
                  >
                </ConcatenateExecute>
                <CaptchaExecute
                  :data="field"
                  v-if="field.input_type == 'CAPTCHA'"
                  :form="form"
                  :is-view="!isExecute"
                  :hasLabel="hasLabel">
                </CaptchaExecute>
              <!-- <pulse-loader
              v-if="
                index == lastInteactiveQuestionIndex &&
                completedFieldsData.length &&
                interactiveQuestionsLoading &&
                fieldsData.length > completedFieldsData.length
              "
            ></pulse-loader> -->
            </template>

            <template
              v-if="
                interactiveQuestionsModeType == 'horizontal' &&
                field.input_type !== 'QUESTION' &&
                field.question_type !== 'TEXT'
              "
            >
              <el-button
                v-if="
                  index == lastInteactiveQuestionIndex &&
                  currentQuestionIndexs[0] != 0
                "
                @click="moveToPrevQuestion"
                style="position: relative; margin-top: 10px"
                size="small"
                :loading="interactiveQuestionsLoading"
                >Prev</el-button
              >
              <!-- Don't remove it -->
              <!-- fieldsData.length != completedFieldsData.length -->
              <el-button
                v-if="index == lastInteactiveQuestionIndex"
                @click="moveToNextQuestion"
                :disabled="isCurrentFieldCompleted"
                style="position: relative"
                size="small"
                :loading="interactiveQuestionsLoading"
                class="mt-1"
                >Next</el-button
              >
            </template>
            <template
              v-else-if="
                field.input_type !== 'QUESTION' &&
                field.question_type !== 'TEXT'
              "
            >
              <el-button
                v-if="index == lastInteactiveQuestionIndex"
                @click="moveToNextQuestion"
                style="position: relative;"
                size="small"
                :disabled="isCurrentFieldCompleted"
                :loading="interactiveQuestionsLoading"
                class="mt-1"
                >Next</el-button
              >
            </template>
            <el-divider></el-divider>
            <b v-if="isFormFormbuilder && index == lastInteactiveQuestionIndex">
              Fields :: {{ lastInteactiveQuestionIndex + 1 }} /
              {{ fieldsData.length }}
            </b>
            <p
              style="margin-top: 50px"
              :id="`currentQuestion` + index"
              title="scrollingPart"
            ></p>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <div class="form-builder" ref="formBuilder" :style="getStylediv" v-else>
      <el-scrollbar
        wrap-style="max-width: calc(100% - 0px);height: calc(100vh - 338px);"
        style="width: 100%; overflow-x: hidden"
      >
        <div
          v-for="(field, index) of fieldsData"
          :key="index"
          :style="getStyle(field)"
        >
          <HorizontalLineExecute
            :data="field"
            v-if="field.input_type === 'HORIZONTAL_LINE'"
            :form="form"
            :is-view="!isExecute"
          ></HorizontalLineExecute>
          <DateExecute
            :data="field"
            v-if="field.input_type === 'DATE'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></DateExecute>
          <DateTimeExecute
            :data="field"
            v-if="field.input_type === 'DATE_TIME'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></DateTimeExecute>
          <DateTimeRangeExecute
            :data="field"
            v-if="field.input_type === 'DATE_TIME_RANGE'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></DateTimeRangeExecute>
          <DateRangeExecute
            :data="field"
            v-if="field.input_type === 'DATE_RANGE'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></DateRangeExecute>

          <TimeExecute
            :data="field"
            v-if="field.input_type === 'TIME'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></TimeExecute>
          <TimeRangeExecute
            :data="field"
            v-if="field.input_type === 'TIME_RANGE'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></TimeRangeExecute>
          <FileExecute
            :data="field"
            v-if="field.input_type === 'FILE'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
            :isExecute="!is - view"
          ></FileExecute>
          <MultiLineTextExecute
            :data="field"
            v-if="
              field.input_type === 'MULTI_LINE_TEXT' ||
              field.input_type === 'RICH_TEXT'
            "
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></MultiLineTextExecute>

          <SingleLineTextExecute
            :data="field"
            v-if="
              field.input_type === 'SINGLE_LINE_TEXT' ||
              field.input_type === 'MASKED' ||
              field.input_type === 'HYPER_LINK' ||
              field.input_type === 'PASSWORD' ||
              field.input_type === 'EMAIL'
            "
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></SingleLineTextExecute>
          <!-- <EmailExecute
            :data="field"
            v-if="field.input_type === 'EMAIL'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></EmailExecute>
          <PasswordExecute
            :data="field"
            v-if="field.input_type === 'PASSWORD'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></PasswordExecute> -->
          <MaskedExecute
            :data="field"
            v-if="field.input_type === 'MASKED'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></MaskedExecute>
          <MultiSelectExecute
            :data="field"
            v-if="field.input_type === 'MULTI_SELECT'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></MultiSelectExecute>
          <SelectExecute
            :data="field"
            v-if="field.input_type === 'SELECT'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></SelectExecute>
          <YesOrNoExecute
            :data="field"
            v-if="field.input_type === 'YES_OR_NO'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></YesOrNoExecute>
          <NumberExecute
            :data="field"
            v-if="field.input_type === 'NUMBER'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></NumberExecute>
          <PhoneCountryCodeExecute
            :data="field"
            v-if="field.input_type === 'PHONE_COUNTRY_CODE'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></PhoneCountryCodeExecute>

          <CurrencyExecute
            :data="field"
            v-if="field.input_type === 'CURRENCY'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></CurrencyExecute>

          <PaymentExecute
            :data="field"
            v-if="field.input_type === 'PAY_BUTTON'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></PaymentExecute>

          <HeadingExecute
            :data="field"
            v-if="field.input_type === 'HEADING'"
            :form="form"
            :is-view="!isExecute"
          ></HeadingExecute>
          <CheckBoxExecute
            :data="field"
            v-if="field.input_type === 'CHECKBOX'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
            :fieldsData="getFieldsData"
            :singleCompanyTemplateCheckBoxArray="
              singleCompanyTemplateCheckBoxArray
            "
          ></CheckBoxExecute>
          <RadioExecute
            :data="field"
            v-if="field.input_type === 'RADIO'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
            :fieldsData="getFieldsData"
          ></RadioExecute>
          <RadioGroupExecute
            :data="field"
            v-if="field.input_type === 'RADIO_BUTTON_GROUP'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
            :fieldsData="getFieldsData"
          ></RadioGroupExecute>

          <CheckBoxGroupExecute
            :data="field"
            v-if="field.input_type === 'CHECKBOX_GROUP'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
            :fieldsData="getFieldsData"
          ></CheckBoxGroupExecute>

          <ESignatureExecute
            :data="field"
            v-if="field.input_type === 'SIGNATURE'"
            :form="form"
            :hasLabel="hasLabel"
          ></ESignatureExecute>
          <GlobalVariableExecute
            :data="field"
            v-if="field.input_type === 'GLOBAL_VARIABLE'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
            :fieldsData="fieldsData"
          ></GlobalVariableExecute>

          <EntityVariableExecute
            :data="field"
            v-if="field.input_type === 'ENTITY_VARIABLE'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
            :fieldsData="fieldsData"
            :class="'dataTable' + index"
          ></EntityVariableExecute>

          <EntityExecute
            :data="field"
            v-if="field.input_type === 'ENTITY'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
            :fieldsData="fieldsData"
          ></EntityExecute>

          <ParagraphExecute
            :data="field"
            v-if="field.input_type === 'PARAGRAPH'"
            :form="form"
            :is-view="!isExecute"
          ></ParagraphExecute>
          <SingleLineContentExecute
            :data="field"
            v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></SingleLineContentExecute>
          <AuthorizedSignatureExecute
            :data="field"
            v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
            :form="form"
            :is-view="!isExecute"
          ></AuthorizedSignatureExecute>
          <FormulaExecute
            :data="field"
            v-if="field.input_type === 'FORMULA'"
            :form="form"
            :is-view="!isExecute"
            :workflowData="getUserWorkflowData"
          ></FormulaExecute>

          <ActionButtonExecute
            :data="field"
            v-if="field.input_type === 'ACTION_BUTTON'"
            :form="form"
            :is-view="!isExecute"
            :workflowDataId="workflowDataId"
            :fieldsData="fieldsData"
          ></ActionButtonExecute>

          <ImageExecute
            :data="field"
            v-if="field.input_type === 'IMAGE'||
            field.input_type === 'GIF'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></ImageExecute>
          <VideoExecute
            :data="field"
            v-if="field.input_type === 'VIDEO'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></VideoExecute>
          <HtmlExecute
            :data="field"
            v-if="field.input_type === 'HTML_CONTENT'"
            :form="form"
            :is-view="!isExecute"
          ></HtmlExecute>

          <QuestionExecute
            :data="field"
            v-if="field.input_type === 'QUESTION'"
            :form="form"
            :is-view="!isExecute"
            @question-answered="moveToNextQuestion"
          ></QuestionExecute>

          <ListExecute
            :data="field"
            v-if="field.input_type === 'LIST'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
          ></ListExecute>
          <AggregateFunctionExecute
            :data="field"
            v-if="field.input_type === 'AGGREGATE_FUNCTION'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel"
            :workflowDataId="workflowDataId"
          ></AggregateFunctionExecute>
          <WeekDaysExecute
            :data="field"
            v-if="field.input_type == 'WEEKDAYS'"
            :form="form"
            :hasLabel="hasLabel"
            :is-view="!isExecute"
          >
          </WeekDaysExecute>
          <StarRatingExecute
            :data="field"
            v-if="field.input_type == 'STAR_RATING'"
            :form="form"
            :hasLabel="hasLabel"
            :is-view="!isExecute"
          >
          </StarRatingExecute>
          <DataTableExecute
            :class="'dataTable' + index"
            ref="dataTable"
            :data="field"
            v-if="field.input_type === 'DATA_TABLE'"
            :form="form"
            :hasLabel="hasLabel"
            :fieldsData="fieldsData"
            @updateTableData="updateTableFieldData"
            @onNewRowAdded="updateTopForBelowElements(index)"
          ></DataTableExecute>
          <ConcatenateExecute
            :data="field"
            v-if="field.input_type === 'CONCATENATE'"
            :form="form"
            :hasLabel="hasLabel"
            :is-view="!isExecute"
          ></ConcatenateExecute>
          <CaptchaExecute
            :data="field"
            v-if="field.input_type == 'CAPTCHA'"
            :form="form"
            :is-view="!isExecute"
            :hasLabel="hasLabel">
          </CaptchaExecute>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import VueScrollTo from "vue-scrollto";
var fieldsList = require("./fields.json");

export default {
  components: {
    HorizontalLineExecute: () =>
      import("./formComponentsExecute/HorizontalLineExecute"),
    DateExecute: () => import("./formComponentsExecute/DateExecute"),
    DateTimeExecute: () =>
      import("./formComponentsExecute/DateTimeExecute.vue"),
    DateTimeRangeExecute: () =>
      import("./formComponentsExecute/DateTimeRangeExecute"),
    DateRangeExecute: () => import("./formComponentsExecute/DateRangeExecute"),
    TimeExecute: () => import("./formComponentsExecute/TimeExecute"),
    TimeRangeExecute: () =>
      import("./formComponentsExecute/TimeRangeExecute.vue"),
    FileExecute: () => import("./formComponentsExecute/FileExecute"),
    MultiLineTextExecute: () =>
      import("./formComponentsExecute/MultiLineTextExecute"),
    SingleLineTextExecute: () =>
      import("./formComponentsExecute/SingleLineTextExecute"),
    MultiSelectExecute: () =>
      import("./formComponentsExecute/MultiSelectExecute"),
    NumberExecute: () => import("./formComponentsExecute/NumberExecute"),
    SelectExecute: () => import("./formComponentsExecute/SelectExecute"),
    YesOrNoExecute: () => import("./formComponentsExecute/YesOrNoExecute"),
    HeadingExecute: () => import("./formComponentsExecute/HeadingExecute"),
    CheckBoxExecute: () => import("./formComponentsExecute/CheckBoxExecute"),
    RadioExecute: () => import("./formComponentsExecute/RadioExecute"),
    RadioGroupExecute: () =>
      import("./formComponentsExecute/RadioButtonGroupExecute"),
    ESignatureExecute: () =>
      import("./formComponentsExecute/ESignatureExecute"),
    ParagraphExecute: () => import("./formComponentsExecute/ParagraphExecute"),
    GlobalVariableExecute: () =>
      import("./formComponentsExecute/GlobalVariableExecute"),
    EntityVariableExecute: () =>
      import("./formComponentsExecute/EntityVariableExecute"),
    EntityExecute: () => import("./formComponentsExecute/EntityExecute"),
    SingleLineContentExecute: () =>
      import("./formComponentsExecute/SingleLineContentExecute"),
    AuthorizedSignatureExecute: () =>
      import("./formComponentsExecute/AuthorizedSignatureExecute"),
    ImageExecute: () => import("./formComponentsExecute/ImageExecute"),
    VideoExecute: () => import("./formComponentsExecute/VideoExecute"),
    FormulaExecute: () => import("./formComponentsExecute/FormulaExecute"),
    ActionButtonExecute: () =>
      import("./formComponentsExecute/ActionButtonExecute"),
    HtmlExecute: () => import("./formComponentsExecute/HtmlExecute"),

    ListExecute: () => import("./formComponentsExecute/ListExecute"),
    AggregateFunctionExecute: () =>
      import("./formComponentsExecute/AggregateFunctionExecute"),
    // RepeatableTemplateExecute: () => import("./RepeatableTemplateExecute.vue"),
    PhoneCountryCodeExecute: () =>
      import("./formComponentsExecute/PhoneCountryCodeExecute.vue"),
    CurrencyExecute: () =>
      import("./formComponentsExecute/CurrencyExecute.vue"),
    PaymentExecute: () => import("./formComponentsExecute/PaymentExecute.vue"),

    QuestionExecuteInteractive: () =>
      import("./formComponentsExecute/QuestionExecuteInteractive"),
    QuestionExecute: () => import("./formComponentsExecute/QuestionExecute"),
    EmailExecute: () => import("./formComponentsExecute/EmailExecute.vue"),
    WeekDaysExecute: () =>
      import("./formComponentsExecute/WeekDaysExecute.vue"),
    StarRatingExecute: () =>
      import("./formComponentsExecute/StarRatingExecute.vue"),
    DataTableExecute: () => import("./formComponentsExecute/DataTableExecute"),
    CheckBoxGroupExecute: () => import("./formComponentsExecute/CheckBoxGroupExecute"),
    ConcatenateExecute: () =>
      import("./formComponentsExecute/ConcatenateExecute"),
    CaptchaExecute: () =>
      import("./formComponentsExecute/CaptchaExecute.vue"),
    PulseLoader,
  },

  props: [
    "fieldsData",
    "templateData",
    "isExecute",
    "form",
    "interactiveQuestionsModeType",
    "dataId",
    "isFormFormbuilder",
    "resetQuestionireForm"
  ],
  data() {
    return {
      repeatableTemplateFields: [],
      currentFormBuilderWidth: null,
      name: "",
      description: "",
      type: "",
      slug: "",
      storedFormBuilderWidth: null,
      isFlow: false,
      componentName: "",
      formHeight: 600,
      singleCompanyTemplateCheckBoxArray: [],
      loading: false,
      templateFormData: {},
      templateCode: null,
      showForm: true,
      existedTemplateDataId: "",
      existedTemplateDataValues: {},
      preparingFormDataLoading: false,
      loadingText: "Loading Template..",

      fieldActions: [],
      templateStyles: {},
      hasRepeatableTemplate: false,
      refreshTemplate: false,
      tableStartingPoint: 0,
      loadGV: false,
      requiredFields: [],
      pos: {
        x: 0,
        y: 0,
        height: 0,
      },
      hasLabel: true,
      intervalTime: "",
      completedFieldsData: [],
      interactiveQuestionsLoading: false,
      lastInteactiveQuestionIndex: -1,
      currentQuestionIndexs: [],
    };
  },

  computed: {
    ...mapGetters("companyTemplates", [
      "getTemplateAddStatus",
      "getTemplateUpdateStatus",
      "getSingleCompanyTemplate",
      "getSingleDefaultTemplate",
      "getTemplateErrors",
    ]),

    ...mapGetters("workflowData", [
      "getNewWorkflowData",
      "getWorkflowDataCreateByTemplateStatus",
      "getWorkflowTemplateDataAddStatus",
      "getNewWorkflowUser",
      "getUserWorkflowData",
    ]),

    ...mapGetters("auth", ["getEntityWorkflowPasscodeData"]),

    ...mapGetters("templatesData", [
      "getTemplatesData",
      "getTemplatesDataUpdateStatus",
      "getFileUploadURL",
      "getFileUploadRefId",
      "getDocumentUploadStatus",
      "getDocumentUploadStatusUpdated",
      "getUploadedFileData",
    ]),

    ...mapGetters("standardFields", [
      "getFList",
      "getField",
      "getGeneralErrorMessage",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    isCurrentFieldCompleted() {
      const currentField =
        this.completedFieldsData[this.lastInteactiveQuestionIndex];
      if (
        currentField &&
        currentField.key &&
        currentField.validations &&
        currentField.validations.required
      ) {
        return !(
          this.form[currentField.key] &&
          this.form[currentField.key] != "" &&
          this.form[currentField.key] != null &&
          this.form[currentField.key] != undefined
        );
      }
      return false;
    },
    textFieldPreviewClass() {
      return (inputType) => {
        const styles = ["common-field-preview"];
        if (
          inputType === "SINGLE_LINE_TEXT" ||
          inputType === "PASSWORD" ||
          inputType === "EMAIL" ||
          inputType === "MASKED" ||
          inputType === "HYPER_LINK"
        ) {
          styles.push("single-line-text-field-preview");
        } else if (inputType === "MULTI_LINE_TEXT") {
          styles.push("multi-line-text-field-preview");
        } else if (
          inputType === "NUMBER" ||
          inputType == "PHONE" ||
          inputType == "CURRENCY"
        ) {
          styles.push("number-field-preview");
        } else if (inputType === "DATE") {
          styles.push("date-field-preview");
        }

        return styles;
      };
    },
    getEntityDataId() {
      if (
        this.getEntityWorkflowPasscodeData &&
        this.getEntityWorkflowPasscodeData.entity_data_id
      ) {
        return this.getEntityWorkflowPasscodeData.entity_data_id;
      } else {
        return null;
      }
    },

    getEntityDataEmail() {
      if (
        this.getEntityWorkflowPasscodeData &&
        this.getEntityWorkflowPasscodeData.email
      ) {
        return this.getEntityWorkflowPasscodeData.email;
      } else {
        return null;
      }
    },
    getTemplateSlug() {
      return this.name.toLowerCase().split(" ").join("-");
    },

    getStylediv() {
      // return `height:${600 + 200}px;overflow:scroll;`;
      let backgroundColor =
        this.templateData?.styles?.background
          ? this.templateData.styles.background
          : "";

      return `overflow: hidden !important; min-height: calc(100vh - ${
        this.interactiveQuestionsModeType ? 300 : 210
      }px) !important; background: ${backgroundColor} !important;`;
    },
    getFieldsData() {
      this.fieldsData.forEach((f) => {
        if (
          f.input_type == "ACTION_BUTTON" &&
          f.action_button_type === "TEMPLATE"
        ) {
          this.pos.x = f.x;
          this.pos.y = f.y;
          this.pos.height = f.height;
        }
      });
      this.fieldsData.forEach((f) => {
        if (
          f.input_type != "ACTION_BUTTON" &&
          (this.pos.x > 0 || this.pos.y > 0 || this.pos.height > 0)
        ) {
          if (this.pos.y < f.y) {
            if (this.pos.y + this.pos.height + 150 < f.y) {
              return;
            } else {
              f.y = this.pos.y + this.pos.height + 200;
            }
          }
        }
      });
      return this.fieldsData;
    },

    getElementStyle() {
      let borderStyle = "";
      if (this.templateStyles) {
        let type = this.templateStyles.border_type
          ? this.templateStyles.border_type
          : "solid";
        let size = this.templateStyles.border_size
          ? this.templateStyles.border_size + "px"
          : "0px";
        let color = this.templateStyles.border_color
          ? this.templateStyles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.templateStyles.background
          ? `background: ${this.templateStyles.background}`
          : "";
      }
      return borderStyle;
    },
    fieldActionsObject() {
      const obj = {};
      this.fieldActions.forEach((action) => {
        obj[action.inputType] = action;
      });

      return obj;
    },

    getStyle() {
      //'margin-top:50px; margin-bottom:20px; width:750px; margin-left:50px;'
      return (item) => {
        if (item) {
          return `position:relative; border: 1px solid #ccc !important; border-radius: 16px; padding:25px; -webkit-box-shadow: -3px 4px 12px 7px rgba(0,0,0,0.05);
-moz-box-shadow: -3px 4px 12px 7px rgba(0,0,0,0.05);
box-shadow: -3px 4px 12px 7px rgba(0,0,0,0.045); ${
            this.getIsMobile ? "width: 85% !important;" : ""
          } `;
          // return style;
        } else {
          return "";
        }
      };
    },
  },
  beforeDestroy() {
    bus.$off("move-to-question", (index) => {
      console.log("Index", index);
    });
  },
  async mounted() {
    if (this.resetQuestionireForm) {
      this.completedFieldsData = [];
      this.lastInteactiveQuestionIndex = this.fieldsData.length - 1;
      this.currentQuestionIndexs = [];
      for (let i = 0; i < this.fieldsData.length; i++) {
        this.completedFieldsData.push(this.fieldsData[i]);
        this.currentQuestionIndexs.push(i);
      }
      // this.showNextInteractiveQuestion(this.currentQuestionIndexs, "push");
    } else {
      this.completedFieldsData = [];
      let indexs = this.findQuestionIndexs(0);
      this.showNextInteractiveQuestion(indexs, "push");
    }
    bus.$on("move-to-question", (index) => {
      this.moveToMentionedIndex(index);
    });
  },

  methods: {
    moveToMentionedIndex(index) {
      this.completedFieldsData = [];
      this.lastInteactiveQuestionIndex = -1;
      this.currentQuestionIndexs = [];
      for (let i = 0; i < index; i++) {
        this.completedFieldsData.push(this.fieldsData[i]);
      }
      let indexs = this.findQuestionIndexs(index);
      this.showNextInteractiveQuestion(indexs, "push");
    },
    getDefaultSizeRangesByFieldType(inputType) {
      if (
        inputType === "SINGLE_LINE_TEXT" ||
        inputType === "PASSWORD" ||
        inputType === "EMAIL" ||
        inputType === "MASKED" ||
        inputType === "HYPER_LINK"
      ) {
        inputType = "SINGLE_LINE_TEXT";
      } else if (inputType === "MULTI_LINE_TEXT" || inputType === "RICH_TEXT") {
        inputType = "SINGLE_LINE_TEXT";
      } else if (
        inputType === "NUMBER" ||
        inputType === "PHONE" ||
        inputType === "CURRENCY"
      ) {
        inputType = "NUMBER";
      } else if (
        inputType === "DATE" ||
        inputType === "TIME" ||
        inputType === "DATE_TIME"
      ) {
        inputType = "DATE";
      }

      let data = this.fieldActionsObject[inputType];
      return {
        min_height: data.min_height,
        min_width: data.min_width,
        max_height: data.max_height,
        max_width: data.max_width,
      };
    },

    updateHeight({ start, end }) {
      this.fieldsData.forEach((f) => {
        if (f.y > start && f.y <= end) {
          f.y = end + (f.y - start);
        }
      });
    },

    next() {
      if (!this.isExecute) {
        bus.$emit("next-step", this.form);
        return;
      }
    },

    goToEditWorkflow() {
      if (this.$route.path.indexOf("/emp/") > -1) {
        let urlData = this.$router.resolve({
          path: `/emp/wf/${this.$route.params.workflow_code}/${this.$route.params.template_code}/${this.workflowDataId}`,
        });
        window.location.href = urlData.href;
      } else {
        let urlData = this.$router.resolve({
          path: `/wf/${this.$route.params.workflow_code}/${this.$route.params.template_code}/${this.workflowDataId}`,
        });
        window.location.href = urlData.href;
      }
    },
    checkRequiredFields() {
      let showError = false;
      this.requiredFields = [];
      this.fieldsData.forEach((field) => {
        if (field.validations && field.validations.required) {
          if (
            field.validations.type &&
            field.validations.type == "ARRAY" &&
            field.key &&
            this.form[field.key] &&
            !this.form[field.key].length
          ) {
            showError = true;
            this.requiredFields.push(field.label);
          } else if (field.key && !this.form[field.key]) {
            showError = true;
            this.requiredFields.push(field.label);
          }
        }
      });
      return showError;
    },

    calculateRelativeLeft(x) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * x
        );
      }
      return x;
    },

    calculateRelativeTop(y) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * y
        );
      }
      return y;
    },
    getHandleByType(type) {
      let defaultHandles = ["tl", "tm", "tr", "mr", "br", "bm", "bl", "ml"];
      switch (type) {
        case "SELECT":
          return ["mr", "ml"];
        case "date":
          return ["mr", "ml"];
        case "CHECKBOX":
          return defaultHandles;
        case "RADIO":
          return defaultHandles;
        case "MULTI_LINE_TEXT":
          return ["mr", "ml"];
        default:
          return defaultHandles;
      }
    },
    calculateRelativeWidth(width) {
      if (
        this.storedFormBuilderWidth &&
        this.currentFormBuilderWidth &&
        this.currentFormBuilderWidth != this.storedFormBuilderWidth
      ) {
        return Math.round(
          (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
        );
      }
      return width;
    },
    setSizeAndPositionForElements(elements) {
      let questionIndex = 1;
      return elements.map((field) => {
        field = {
          ...field,
          ...this.getDefaultSizeRangesByFieldType(field.input_type),
        };
        field.x = this.calculateRelativeLeft(field.x);
        field.y = this.calculateRelativeTop(field.y);

        let width = this.calculateRelativeWidth(field.width);
        let height = this.calculateRelativeWidth(field.height);

        if (width > field.max_width) {
          field.width = field.max_width;
        } else if (width < field.min_width) {
          field.width = field.min_width;
        } else {
          field.width = width;
        }
        if (height > field.max_height) {
          field.height = field.max_height;
        } else if (height < field.min_height) {
          field.height = field.min_height;
        } else {
          field.height = height;
        }
        if (field.type == "FIELD") {
          field.questionIndex = questionIndex;
          questionIndex = questionIndex + 1;
        }
        return field;
      });
    },
    async setTemplateElements(templateData) {
      this.name = templateData.name;
      this.description = templateData.description;
      this.type = templateData.type;
      this.slug = templateData.slug;
      this.templateStyles = templateData.styles;

      this.storedFormBuilderWidth = templateData.page_base_width;
      if (this.type == "STANDARD") {
        this.showFields = false;
      } else {
        let defaultSection = templateData.sections.find(
          (x) => x.type == "DEFAULT"
        ); // Todo need to change for multiple sections
        // this.fieldsData = defaultSection.fields;

        // we are sorting all fields depends on x,y cordinates to flow in the form
        defaultSection.fields = defaultSection.fields.sort((a, b) => {
          if (a.y - b.y < 10) {
            return a.x - b.x;
          }
          return a.y - b.y;
        });

        this.fieldsData = this.setSizeAndPositionForElements(
          defaultSection.fields
        );
        if (
          templateData &&
          templateData.sections &&
          templateData.sections[0] &&
          templateData.sections[0].checkBoxGroup
        ) {
          this.singleCompanyTemplateCheckBoxArray =
            templateData.sections[0].checkBoxGroup;
        }
        this.fieldsData = this.fieldsData.sort((a, b) => a.order - b.order);
        this.repeatableTemplateFields = this.fieldsData.filter(
          (e) =>
            e.input_type == "ACTION_BUTTON" &&
            e.action_button_repeating_template
        );
        if (this.templateDataId && this.getTemplatesData) {
          this.showForm = false;
          this.setTemplateDataToFormFields(
            this.fieldsData,
            this.getTemplatesData.template_data
          );
        } else if (
          this.existedTemplateDataId &&
          this.existedTemplateDataValues
        ) {
          this.setTemplateDataToFormFields(
            this.fieldsData,
            this.existedTemplateDataValues
          );
        }

        defaultSection.fields = defaultSection.fields.sort((a, b) => {
          return a.y - b.y;
        });

        let lastElement = defaultSection.fields[this.fieldsData.length - 1];

        this.tableStartingPoint = lastElement.y + lastElement.height + 30;

        this.formHeight = lastElement.y + lastElement.height + 200;
      }
    },

    setTemplateDataToFormFields(fieldsData, data) {
      return fieldsData.forEach((field) => {
        if (field.input_type === "PHONE_COUNTRY_CODE") {
          this.$set(
            this.form,
            field.key + "_code",
            data[field.key + "_code"] ? data[field.key + "_code"] : {}
          );
        }
        if (field.input_type === "CURRENCY") {
          this.$set(
            this.form,
            field.key + "_currency",
            field.validations.currency
          );
        }
        if (
          field.inputType === "SINGLE_LINE_TEXT" &&
          field.input_type === "MASKED"
        ) {
          this.$set(
            this.form,
            field.key + "_masked",
            data && data[field.key + "_masked"]
              ? data[field.key + "_masked"]
              : ""
          );
        }
        if (field.input_type == "QUESTION" && field.has_other_option) {
          this.$set(
            this.form,
            `${field.key}/otheroption`,
            data[field.key + "/otheroption"]
              ? data[field.key + "/otheroption"]
              : ""
          );
        }
        if (data && data[field.key]) {
          this.$set(this.form, field.key, data[field.key]);
        }
      });
    },

    async saveForm() {
      bus.$emit("finish", this.form);
    },
    async fetchUserTemplateData() {
      await this.$store.dispatch("templatesData/fetchUserTemplateData", {
        template_data_id: this.templateDataId,
      });
    },
    async fetchUserExistedTemplateData() {
      if (
        this.$route.name !== "GuestWorkFlowExecuteUpdateStep" &&
        this.$route.name !== "EntityWorkFlowExecuteStep" &&
        this.$route.name !== "EntityWorkFlowExecute"
      ) {
        if (this.getNewWorkflowUser || this.getUserWorkflowData.user_id) {
          await this.$store.dispatch("templatesData/fetchUserTemplateData", {
            user_id:
              this.getNewWorkflowUser || this.getUserWorkflowData.user_id,
            template_id: this.templateData._id,
          });
          if (this.getTemplatesData && this.getTemplatesData.length) {
            this.existedTemplateDataId = this.getTemplatesData[0]._id;
            this.existedTemplateDataValues =
              this.getTemplatesData[0].template_data;
          }
        } else {
          this.existedTemplateDataId = null;
          this.existedTemplateDataValues = {};
        }
      } else {
        // await this.$store.dispatch("templatesData/fetchUserTemplateData", {
        //   user_id: this.getNewWorkflowData.workflow_user_id,
        //   template_id: this.templateData._id,
        // });
        // if (this.getTemplatesData && this.getTemplatesData.length) {
        //   this.existedTemplateDataId = this.getTemplatesData[0]._id;
        //   this.existedTemplateDataValues =
        //     this.getTemplatesData[0].template_data;
        // }

        this.existedTemplateDataId = null;
        this.existedTemplateDataValues = {};
      }
    },

    async prepareFormData() {
      this.preparingFormDataLoading = true;
      this.loadingText = "Preparing template data...";
      for (let [key, value] of Object.entries(this.form)) {
        if (
          typeof value == "object" &&
          value.type == "DOCUMENT" &&
          value.new == true &&
          value.files
        ) {
          this.loadingText = "Uploading files...";
          let path = await this.uploadFile(value.files);
          this.form[key].path = path;
        }
      }

      this.preparingFormDataLoading = false;
      return this.form;
    },

    // methods to file upload

    async uploadFile(file) {
      file.forEach(async (el) => {
        await this.getFileUploadUrl(el);
        await this.uploadSelectedDocument(el.raw);
        if (this.getUploadedFileData) {
          return (
            this.getUploadedFileData.slug + "/" + this.getUploadedFileData.key
          );
        } else {
          return "";
        }
      });
    },
    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch("templatesData/fetchDocumentUploadUrl", data);
    },
    async uploadSelectedDocument(file) {
      this.loadingText = "Uploading Document...";
      // uploding to S3
      let params = {
        file: file,
        upload_url: this.getFileUploadURL,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentToGeneratedUrl",
        params
      );

      if (this.getDocumentUploadStatus) {
        // UPDATEING STATUS
        let params = {
          file_ref_id: this.getFileUploadRefId,
        };
        await this.$store.dispatch(
          "templatesData/updateDocumentUploadStatus",
          params
        );
        if (this.getDocumentUploadStatusUpdated) {
          this.$message("Sorry ! Error while file uploading");
        }
      } else {
        this.$message("Sorry ! Error while file uploading");
      }
    },
    async getAllFieldList() {
      try {
        // let params = "form=true&content=true"
        // await this.$store.dispatch("standardFields/getFieldLists", params);
        if (fieldsList) {
          //   let dFieldList = this.fieldsList;
          this.fieldActions = [
            ...fieldsList.allFormFields,
            ...fieldsList.allContentFields,
            ...fieldsList.allAdvancedFields,
          ];
        }
      } catch (error) {
        console.log("getAllFieldList",error);
      }
    },

    findQuestionIndexs(startIndex) {
      let indexs = [];
      while (
        startIndex < this.fieldsData.length &&
        this.fieldsData[startIndex] &&
        this.fieldsData[startIndex].type != "FIELD"
      ) {
        indexs.push(startIndex);
        ++startIndex;
      }

      if (this.fieldsData[startIndex]) {
        indexs.push(startIndex);
      }
      return indexs;
    },

    findPrevQuestionIndexs(startIndex) {
      let indexs = [];

      if (this.fieldsData[startIndex].type == "FIELD") {
        indexs.push(startIndex);
        startIndex--;
      }

      while (
        startIndex < this.fieldsData.length &&
        this.fieldsData[startIndex] &&
        this.fieldsData[startIndex].type != "FIELD"
      ) {
        indexs.push(startIndex);
        --startIndex;
      }

      return indexs;
    },

    moveToNextQuestion() {
      let indexs = this.findQuestionIndexs(
        this.lastInteactiveQuestionIndex + 1
      );
      let currentField = this.fieldsData[this.currentQuestionIndexs[0]];
      if (currentField?.input_type == "CAPTCHA"){
        if (!this.form[currentField.key]){
          this.$message.error("Captcha verification required! Please complete the captcha verification.");
          return;
        }
      }
      this.showNextInteractiveQuestion(indexs, "push");
    },

    moveToPrevQuestion() {
      this.showNextInteractiveQuestion(this.currentQuestionIndexs, "pull");
    },

    showNextInteractiveQuestion(indexs, type) {
      this.interactiveQuestionsLoading = true;
      setTimeout(() => {
        this.interactiveQuestionsLoading = false;
        this.$emit(
          "onFilledQuestion",
          this.completedFieldsData.length == this.fieldsData.length
            ? true
            : false
        );
        if (type == "push") {
          for (let i = 0; i < indexs.length; i++) {
            let index = indexs[i];
            this.completedFieldsData.push(this.fieldsData[index]);
          }
          this.currentQuestionIndexs = indexs;
        } else {
          for (let i = 0; i < indexs.length; i++) {
            let index = indexs[i];
            this.completedFieldsData.splice(index, 1);
          }
          this.currentQuestionIndexs = this.findPrevQuestionIndexs(
            this.completedFieldsData.length - 1
          );
        }

        this.lastInteactiveQuestionIndex = this.completedFieldsData.length - 1;

        setTimeout(() => {
          VueScrollTo.scrollTo(
            "#currentQuestion" + this.lastInteactiveQuestionIndex,
            100,
            {
              container: "#formBuilder",
            }
          );
        });
      }, 1000);
    },

    doneQuestionare() {
    },

    getFieldYValueFromHeight(item) {
      if (item.input_type == "QUESTION") {
        return 150;
      }
      return 80;
    },
    updateTopForBelowElements(data) {
      this.fieldsData.forEach((e, index) => {
        if (e.listen_height) {
          if (data) {
            this.$set(
              this.fieldsData[index],
              "style",
              this.getStyle(e, index, data.height)
            );
          } else {
            this.$set(this.fieldsData[index], "style", this.getStyle(e, index));
          }
        }
      });
    },
    updateTableFieldData(details) {
      this.$set(this.form, details.key, details.data);
    },
  },
  watch: {
    "$route.params.slug"(slug) {
      this.currentSlug = slug;
    },
    "$route.params.workflow_data_id"(id) {
      this.workflowDataId = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.sub-btn {
  border-radius: 2px;
  padding: 0.45em 0.65em !important;
  border: 1px solid #285ed3;
  background: transparent;
  color: #285ed3 !important;
  &:hover {
    color: white !important;
    background: #285ed3;
    border: 1px solid #285ed3 !important;
  }
}
.edit-btn {
  border-radius: 2px !important;
  padding: 0.45em 0.65em;
  border: 1px solid #f68c8c;
  background: transparent;
  color: #f68c8c !important;
  &:hover {
    color: white !important;
    background: #f68c8c !important;
    border: 1px solid #f68c8c !important;
  }
}
.preview-template-view {
  margin: 1em 0em;
  h1 {
    margin-bottom: 25px !important;
    font-size: 1.125em;
    font-weight: 500;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .preview-form-header {
    margin-top: 1em;
    z-index: 100;
    h1 {
      font-size: 2.1em;
      font-family: "Mulish", sans-serif;
      font-weight: 500;
      letter-spacing: -0.5px;
      color: #1f2f3d;
      margin-bottom: 0.2em !important;
    }
    p {
      margin-top: 0;
      padding: 0.15em 0 1.5em;
      line-height: 1.5 !important;
      color: #5e6d82;
    }
  }
  .header-action-block {
    .default-button {
      display: block;
      text-transform: uppercase;
      color: #5e6d82;
      min-width: 100px;
      margin: 0.5em 0 0 auto;
    }
  }
  .footer-action-block {
    position: relative;
    margin-bottom: 2em;
    .el-button {
      margin: 2em auto 0;
      display: block;
      color: #ffffff;
      padding: 0.5em 1em;
      min-width: 150px;
      font-size: 1em;
      border-radius: 30px !important;
    }
    .required-fields-info {
      top: 10px;
      font-size: 14px;
      & > div {
        margin-right: 1.5em;
        border: 1px solid #eeeeee;
        padding: 0.65em 1em;
        border-radius: 4px;
        font-size: 1em;
        letter-spacing: 0.5px;
      }
    }
  }
  .form-builder {
    position: relative;
    width: 100%;

    // min-height: calc(100vh - 210px) !important;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.05);
    .form-fields-holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      // height: 100%;

      .each-element img {
        max-width: 100%;
      }
    }
  }
}
.template-body-section {
  .form-builder {
    .form-fields-holder {
      @media (max-width: 991.98px) {
        & > div {
          position: relative !important;
          left: 10px !important;
          top: 0px !important;
          margin-top: 30px;
          width: calc(100% - 20px) !important;
          input {
            width: 100%;
          }
          .heading {
            margin-top: 40px !important;
          }
        }
      }
    }
  }
}
</style>
